export const siteData = {
  "name": "NOERATI",
  "address": "18 BOULEVARD MONTMARTRE,",
  "postCode": "75009",
  "city": "paris",
  "phone": "0453760248",
  "url": "https://noerati.store",
  "urlSub": "noerati.store",
  "price": "price_1Q54R8KdqOGdukc8fKxMKjKJ",
  "pk": "pk_live_51Q54MtKdqOGdukc8evs7HPEBtdcq031Gc0hVZCjZhzUj7IeG4Mxhk5zoyVz9Ya9uWvnLZfx8yFOHvbsERR5EiGV200mUR36oCI",
  "sk": "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"
};